.main-navigation {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	background-color: #202020;
	min-height: 55px;
}

.main-navigation nav {
	padding: 0;
	justify-content: flex-start;
	max-width: 1500px;
	margin: 0 auto;
}

.main-navigation .navbar-brand {
	padding: 0 12px;
    margin: 0;
}

.main-navigation .navbar-toggler {
	color: white;
	outline: none;
	height: 55px;
	line-height: 1.2;
}

.main-navigation .company-application {
	margin-left: auto;
}

.app-navigation {
	display: -webkit-box;
	display: flex;
	position: fixed;
	-webkit-box-align: stretch;
	align-items: stretch;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10000;
	min-height: 55px;
	background-color: #202020;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: -webkit-transform .4s;
	transition: -webkit-transform .4s;
	transition: transform .4s;
	transition: transform .4s, -webkit-transform .4s
}

.app-navigation__container {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
	-webkit-box-align: stretch;
	align-items: stretch;
	overflow: visible;
	max-height: 55px;
	text-align: center;
}

.app-navigation__container .an-primary-links__list-item {
	-webkit-animation-delay: initial;
	animation-delay: 0s;
	-webkit-animation: initial;
	animation: initial;
	will-change: rotateY
}

.app-navigation__wrapper {
	width: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	max-height: 100vh
}

.app-navigation__action-bar-wrapper .an-primary-links__list-item--home {
	padding: 0 12px;
}

.header-and-content {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    min-height: 1px;
}

.header-and-content.header-and-content--one-row-offset {
	margin-top: 55px;
}

@media screen and (min-width: 1025px) {
	.header-and-content {
		margin-top: 165px;
	}
}

.an-primary-links {
	position: relative;
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: 100%;
    display: flex;
    justify-content: space-between !important;
	align-items: flex-start !important;
	padding: 0 !important;
}

.an-primary-links__list {
	list-style: none;
	height: 100%;
	margin: 0;
	flex-grow: initial !important;
}

.an-primary-links__list-item {
	height: 100%;
	-webkit-box-align: stretch;
	align-items: stretch;
	margin: 0;
	padding: 0 12px;
	height: 55px;
	float: left;
	display: -webkit-inline-box;
	display: inline-flex;
}

.an-primary-links__list-item--home {
	display: -webkit-inline-box;
	display: inline-flex;
	white-space: nowrap
}

.an-primary-links__link {
	background: none;
	border: 0;
	color: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	font-family: futura-pt, Helvetica, arial, sans-serif;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	color: #e1e1df;
	font-weight: 400;
	font-size: 17px
}

.an-primary-links__link:focus {
	outline: none
}

.an-primary-links__link:focus-visible {
	box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000
}

[lang=ja] .an-primary-links__link {
	font-family: futura-pt, source-han-sans-japanese, Helvetica, sans-serif
}

[lang=zh-Hans] .an-primary-links__link {
	font-family: futura-pt, Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif
}

.an-primary-links__link:focus {
	outline: none
}

.an-primary-links__list-item--focus {
	background: #fff
}

.an-primary-links__list-item--focus .an-primary-links__link {
	color: #888
}

.an-primary-links__link:focus-visible {
	box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000
}

.an-primary-links__link:active,
.an-primary-links__link:focus,
.an-primary-links__link:hover,
.an-primary-links__list-item:active {
	color: #e1e1df;
	text-decoration: none;
}

.an-primary-links__link .icon {
	-webkit-transition: color .3s;
	transition: color .3s;
	color: #e1e1df;
	margin-left: 3px;
	height: 15px;
	width: 15px;
	display: inline-block
}

.an-primary-links__list-item:hover:not( .an-primary-links__list-item--home) {
	background: #fff
}

.an-primary-links__list-item:hover .an-primary-links__link {
	color: #888
}

.main-navigation__image {
	display: block;
	width: 18px;
	height: 18px;
	color: #fff;
	fill: currentColor;
    stroke: currentColor;
}

.main-navigation__image_login {
	display: block;
	width: 25px;
	height: 25px;
	color: #fff;
	fill: currentColor;
	stroke: currentColor;
	margin-left: 15px;
}

.tokenDiv{
	margin-top: 10px;
	white-space: pre-wrap;
	  white-space: -moz-pre-wrap;
	  white-space: -pre-wrap;
	  white-space: -o-pre-wrap;
	  word-wrap: break-word
  }

  .tokenDiv label {
	display: block;
	font-size: small;
  }

  .loginImage {
	width: 25px;
	height: 25px;
	margin-left: 10px;
  }

  .contact-info-link:hover a {
	  text-decoration: none !important;
	  color: #e1e1df !important;
	  cursor: default;
  }

  @media (max-width: 991px) {

	.an-primary-links__list-item {
		display: block !important;
		float: none;
	}

	.an-primary-links__link {
		line-height: 55px !important;
	}

	.an-primary-links .navbar-toggler {
		color: white;
		outline: none;
		height: 55px;
		line-height: 1.2;
	}

	.main-navigation .navbar-collapse {
		order: 2;
	}

	.main-navigation .navbar-toggler {
		order: 1;
	}

	.main-navigation .navbar-toggler svg {
		width: 20px;
		fill: #fff;
	}

	.loginImage {
		margin-left: auto;
	}

	.contact-info-link {
		width: 100%;
	}
}

.an-logo-header {
	position: relative;
	padding-right: 12px;
	padding-left: 12px;
	z-index: 21;
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1)
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__logo {
	width: auto;
	height: 100%
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__logo.an-logo-header__logo--desktop {
	display: none
}

@media screen and (min-width:768px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__logo.an-logo-header__logo--desktop {
		display: block
	}
}

@media screen and (min-width:768px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__logo.an-logo-header__logo--mobile {
		display: none!important
	}
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title {
	line-height: 30px;
	margin-bottom: 6px;
	padding-bottom: 0;
	max-width: calc(100% - 72px)
}

@media screen and (min-width:374px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title {
		font-size: 22px
	}
}

@media screen and (min-width:768px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title {
		font-size: 25px;
		margin-bottom: 12px
	}
}

@media screen and (min-width:1025px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title {
		font-size: 26px;
		margin-bottom: 31px
	}
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title .an-logo-header__title-companyname {
	font-weight: 700
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title .an-logo-header__title-site-name {
	font-weight: 300
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title.an-logo-header__title--no-company {
	font-size: 23px;
	text-transform: none
}

@media screen and (min-width:1025px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title.an-logo-header__title--no-company {
		font-size: 26px;
		margin-bottom: 31px
	}
}

.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__title.an-logo-header__title--no-company .an-logo-header__title-site-name {
	font-weight: 400;
	letter-spacing: normal
}

@media screen and (min-width:768px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__breadcrumb {
		padding-bottom: 12px
	}
}

@media screen and (min-width:1025px) {
	.an-logo-header.an-logo-header--beyond-fossils .an-logo-header__breadcrumb {
		padding-bottom: 34px
	}
}

.an-logo-header--one-pager {
	margin-top: -55px
}

.an-logo-header__container {
	max-width: 1146px;
	margin: 0 auto;
	height: 72px
}

@media screen and (min-width:1025px) {
	.an-logo-header__container {
		height: 90px
	}
}

.an-logo-header__row {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	max-height: 100%;
	height: 100%;
	position: relative
}

.an-logo-header__title {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	font-family: futura-pt, Helvetica, arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	/* text-transform: uppercase; */
	line-height: 20px;
	color: #202020;
	align-self: flex-end;
	padding-bottom: 11px;
	max-width: calc(100% - 100px)
}

[lang=ja] .an-logo-header__title {
	font-family: futura-pt, source-han-sans-japanese, Helvetica, sans-serif
}

[lang=zh-Hans] .an-logo-header__title {
	font-family: futura-pt, Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif
}

.an-logo-header__title:active,
.an-logo-header__title:focus,
.an-logo-header__title:hover {
	color: #202020
}

@media screen and (min-width:480px) {
	.an-logo-header__title {
		font-size: 20px;
		margin-right: 20px
	}
}

@media screen and (min-width:374px) {
	.an-logo-header__title {
		font-size: 23px
	}
}

@media screen and (min-width:768px) {
	.an-logo-header__title {
		padding-bottom: 13px;
		-webkit-box-flex: 0;
		flex: 0 1 auto;
		margin-right: 20px
	}
}

@media screen and (min-width:1025px) {
	.an-logo-header__title {
		font-size: 25px;
		padding-bottom: 22px;
		margin-right: 40px;
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		max-width: calc(100% - 100px)
	}
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (-webkit-min-device-pixel-ratio:2) {
	.an-logo-header__title {
		font-size: 25px;
		padding-bottom: 13px
	}
}

@media screen and (min-width:1440px) {
	.an-logo-header__title {
		font-size: 26px;
		padding-bottom: 23px
	}
}

.an-logo-header__title-companyname {
	font-weight: 600
}

.an-logo-header__center {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	height: 100%;
	overflow-x: hidden
}

.an-logo-header__breadcrumb {
	display: none;
	font-size: 18px;
	line-height: 18px
}

@media screen and (min-width:768px) {
	.an-logo-header__breadcrumb {
		display: block;
		padding-bottom: 13px
	}
}

@media screen and (min-width:1025px) {
	.an-logo-header__breadcrumb {
		display: block;
		padding-bottom: 22px
	}
}

.an-logo-header__logo {
	-webkit-box-flex: 0;
	flex: 0 0 auto
}

.an-logo-header__logo .icon {
	width: auto;
	height: 100%
}

.an-logo-header__logo-wrapper {
	display: -webkit-box;
	display: flex;
	height: 100%;
	-webkit-box-align: end;
	align-items: flex-end;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 0;
	min-width: 45px
}

@media screen and (min-width:768px) {
	.an-logo-header__logo-wrapper {
		padding: 16px 0;
		background: #fff;
		min-width: 130px
	}
	.an-logo-header__logo-wrapper.an-logo-header__logo-wrapper--only-griffin {
		padding: 6px 0
	}
}

@media screen and (min-width:1025px) {
	.an-logo-header__logo-wrapper {
		padding: 20px 0;
		min-width: 170px
	}
	.an-logo-header__logo-wrapper.an-logo-header__logo-wrapper--only-griffin {
		padding: 12px 0
	}
}