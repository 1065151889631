:root {
    --dropdown-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
	--upm-green: #7aba28;
}

/* Global styles */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%
}

h1,h2,h3,h4,h5 {
	font-family: futura-pt, Helvetica, arial, sans-serif;
	font-style: normal;
}

body {
	margin: 0;
	background-color: #fff;
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column
}

ol li,
ul li {
	margin: 0 0 8px 20px;
	color: #333
}

main {
	display: block
}

body,
html {
	font-family: futura-pt, Helvetica, arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height:100%;
	color: #212529;
    text-align: left;
    background-color: #fff;
}

footer {
    display: block;
}

div {
	display: block;
}

.icon {
	width: 1em;
	height: 1em;
	stroke-width: 0;
	color: #000
}

#root {
	height:100%;
}

.app {
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	/*background: url(../../images/upm_timber.jpg) no-repeat transparent;
	background-size:cover*/
}

.container-main, .container-sub {
	max-width: 1500px;
	width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.container-main {
	padding: 30px 15px 15px;
}

.container-sub {
    padding-right: 0px;
    padding-left: 0px;
}

.content-centered {
	text-align: center;
}



/* Buttons */
.btn .btn-icon,
.FormCaptcha .FormCaptcha__Refresh .btn-icon,
.FormSubmitButton .btn-icon,
.submitbuttonelementblock button .btn-icon {
	width: 12px;
	height: 12px
}

.btn .btn-icon.btn-icon--right,
.FormCaptcha .FormCaptcha__Refresh .btn-icon.btn-icon--right,
.FormSubmitButton .btn-icon.btn-icon--right,
.submitbuttonelementblock button .btn-icon.btn-icon--right {
	margin-left: 8px
}

.upm-btn, .upm-btn-small {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    background: transparent;
    border-radius: 25px;
    border: 1px solid #666;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-transition: all .2s cubic-bezier(0,0,.2,1);
    transition: all .2s cubic-bezier(0,0,.2,1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-family: futura-pt,Helvetica,arial,sans-serif;
    color: #444;
    font-weight: 400;
    letter-spacing: -.2px;
    text-transform: none;
    min-width: 80px;
	line-height: 1.15;
	outline: none !important;
}

.upm-btn {
	font-size: 18px;
	padding: 7px 20px;
}

.upm-btn-small {
	font-size: 16px;
	padding: 4px 10px 4px 15px;
}

.upm-btn-group-left-spaced {
	margin-left: 20px;
}

.btn--secondary {
	color: #fff;
	border-color: var(--upm-green);
	background: var(--upm-green);
}

.btn--secondary:focus,
.btn--secondary:hover {
	background: #65ae04;
	color: #fff;
	border-color: #65ae04
}

.btn--secondary:disabled {
	background-color: #abdf69;
	border-color: #abdf69;
	color: #fff;
	cursor: default
}

.btn--secondary:disabled:hover {
	background: #abdf69;
	border-color: #abdf69;
	color: #fff
}

.cta-block__btn {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	max-width: 170px
}

.btn--arrow-left,
.btn--arrow-right {
	text-align: left;
	border: none !important;
	letter-spacing: .3px;
	position: relative;
	padding-left: 40px !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	text-decoration: none;
	margin: 0;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	min-height: 32px
}

.btn--arrow-right,
.btn--arrow-left {
	text-align: left;
	border: none;
	letter-spacing: .3px;
	position: relative;
	padding-left: 40px;
	padding-top: 0;
	padding-bottom: 0;
	text-decoration: none;
	margin: 0;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	min-height: 32px;
	margin-right: 20px;
}

.btn--arrow-right:after,
.btn--arrow-right:before,
.btn--arrow-left:after,
.btn--arrow-left:before {
	content: "";
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: absolute;
	display: block;
	top: 0;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.btn--arrow-left:before,
.btn--arrow-right:before {
	width: 28px;
	height: 28px;
	left: 1px;
	top: 0;
	border: 2px solid #444;
	border-radius: 50%
}

.btn--arrow-left:after,
.btn--arrow-right:after {
	width: 8px;
	height: 8px;
	border-color: transparent #444 #444 transparent;
	border-style: solid;
	border-width: 0 2px 2px 0;
	
}

.btn--arrow-right:after {
	-webkit-transform: rotate(-45deg) translate(.5px, .5px);
	transform: rotate(-45deg) translate(.5px, .5px);
	top: 11px !important;
	left: 9px !important;
}

.btn--arrow-left:after {
	-webkit-transform: rotate(-230deg) translate(.5px, .5px);
	transform: rotate(-230deg) translate(.5px, .5px);
	top: 11px !important;
	left: 13px !important;
}

.btn--primary {
	color: #fff;
	border-color: #1a181d;
	background: #1a181d;
}

.btn--primary:focus,
.btn--primary:hover {
	background: rgba(26, 24, 29, .8);
	color: #fff;
	border-color: rgba(26, 24, 29, .8)
}

/* --------------------------------------------------  */
.input-with-label {
	display: block;
	height: auto;
	font-size: 19px;
    font-family: futura-pt,Helvetica,arial,sans-serif;
	margin: 0 0 10px;
}
.input-with-label a {
	font-weight: 500;
	display: block;
	text-transform: none;
	text-decoration: underline;
	color: #333;
}
.input-with-label a:hover {
	text-decoration: none;
}
.input-with-label label {
	cursor: pointer;
}
.input-with-label label + label {
	margin-left: 20px;
}
.input-with-label .picky__dropdown label {
	margin-left: 0;
}
.input-with-label input:not([type=checkbox]):not([type=radio]) {
	display: block;
	width: 100%;
	padding-left: 5px;
}
.input-with-label input:not([type=checkbox]):not([type=radio]), .input-with-label textarea {
	min-height: 5px;
	padding: 5px 15px;
	border: 1px solid #0000005e;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #1a181d;
}
.input-with-label.minMax input[type=number] {
	width: 70px;
	-moz-appearance: textfield;
   /* Firefox */
}
.input-with-label.minMax input[type=number] + .append {
	padding: 0 10px;
}
.input-with-label.minMax input[type=number]::-webkit-outer-spin-button, .input-with-label.minMax input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.input-with-label.toggle {
	max-height: 40px;
}
.input-with-label.toggle + .toggleDown {
	top: -1px;
}
.input-with-label.disabled > div {
	font-weight: normal;
	text-transform: none;
	font-size: 16px;
	margin-top: 10px;
}
.input-with-label .icon-warning {
	margin: 0 0 0 5px;
}
.input-with-label textarea {
	display: block;
	width: 100%;
}
.input-with-label input[type=checkbox], .input-with-label input[type=radio] {
	position: relative;
    top: 4px;
    display: block;
}
.priceListModal .input-with-label input[type=checkbox], .priceListModal .input-with-label input[type=radio] {
	float: none;
}
.input-with-label input[type=checkbox] + span, .input-with-label input[type=radio] + span {
	display: inline-block;
	margin: 5px 0 5px 5px;
	font-size: 16px;
	text-transform: none;
	font-weight: 500;
}
.input-with-label .dropdown-menu {
	min-width: 100%;
}
.input-with-label .dropdown-menu-item {
	cursor: pointer;
	padding: 5px;
	font-weight: normal;
	text-transform: none;
	white-space: nowrap;
}
.input-with-label .dropdown-menu-item:hover {
	background: #fafafa;
}
.input-with-label--dropdown {
	display: inline-block;
	height: 22px;
	width: 100%;
	line-height: 22px;
	padding-left: 5px;
	padding-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid #0000005e;
	background: #fff;
	font-weight: normal;
}
.input-with-label--dropdown + .dropdown-menu {
	display: block;
	padding: 0;
	border-radius: 0;
}
.input-with-label--dropdown + .dropdown-menu.hidden {
	display: none;
}
.input-with-label.toggle:after {
	margin-left: -16px;
	top: 22px;
}
.input-with-label.required > span.field-label {
	display: block;
	position: relative;
	white-space: nowrap;
}
.input-with-label.required > span.field-label:after {
	content: '*';
	color: red;
	font-weight: bold;
	position: absolute;
	top: -1px;
}

.invalid-field-value {
	background-color: #f8d4d2;
}

.assigned-apps-section.required > span.section-label {
	display: inline;
	position: relative;
	white-space: nowrap;
}
.assigned-apps-section.required > span.section-label:after {
	content: '*';
	color: red;
	font-weight: bold;
	position: absolute;
	top: -5px;
}

.units + .input-with-label__infotext {
	margin-top: 5px;
}
.input-with-label.fullWidth {
	max-height: 75px;
}
.noBlock {
	display: initial;
}

.field-warning-text {
	font-size: 14px;
	display: inline;
}



.tooltip-theme {
    color: black !important;
    background-color: #f4f4c7 !important;
    border-color: 1px solid black !important;
    font-family: futura-pt,Helvetica,arial,sans-serif;
    max-width: 320px !important;
    opacity: 1 !important;
    text-align: left;
    font-size: 14px !important;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
}

.tooltip-theme::after { 
    left: 75% !important; 
}

.warning-message-line {
	font-family: futura-pt,Helvetica,arial,sans-serif;
	padding: 0;
	margin:0
}

.an-logo-header__container {
	max-width: 1500px !important;
	padding: 0 15px;
}